import React from "react"
//import { Link } from "gatsby"

import PortfolioItems from "../components/portfolio-items"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PortfolioPage = () => (
  <Layout>
    <SEO title="Portfolio" />
    <div className="portfolio-page">
      <div className="heading">
        <h1>Projects</h1>
      </div>
      <PortfolioItems />
    </div>
  </Layout>
)

export default PortfolioPage
